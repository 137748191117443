import { externalFetch } from '@telekomconsalting/dex-guru-fetch'
import { BigNumber } from 'bignumber.js'
import qs from 'query-string'
import Web3 from 'web3'
import { TransactionReceipt } from 'web3-core'

import { GTM_PURCHASE_TRESHOLD_TOP, referrerAddress, RPC_ERR } from '../config/settings'
import { ZERO_X_NATIVE_TOKEN_ADDRESS } from '../config/tokens'
import {
  DEFAULT_ERROR_MESSAGE,
  GAS_ERROR_MESSAGE,
  LOST_QUOTE_RESPONSE_ERROR_MESSAGE,
  MAX_UINT,
  QUOTE_RESPONSE_ERROR_MESSAGE,
  REQUEST_DENIED_BY_USER_ERROR_CODE,
  RPC_ERROR_MESSAGE,
  RPC_ERROR_MESSAGE_POLYGON,
  WALLET_EXECUTE_DELEGATE_ERROR_MESSAGE,
  ZERO_X_ERROR_MESSAGE,
  ZERO_X_ERRORS,
} from '../constants'
import { AmplitudeEvent } from '../constants/amplitudeEvents'
import { feeOnTransferTokens } from '../constants/feeOnTransferTokens'
import TradeTypeEnum from '../enums/TradeTypeEnum'
import { calculateGasPrices } from '../helpers/verifyHelpers'
import {
  Address,
  ErrorKind,
  GasFeeType,
  GasPrice,
  GasPriceEIP1559,
  HttpError,
  QueryFee,
  QuoteResponse,
  Source,
  TokenSpenders,
  TokenV3,
  TokenWithApprovalContext,
  TradeType,
  Transaction,
  TxnParams,
  ZeroXGasApiResponse,
  ZeroXGasApiResponseItem,
  ZeroXWrapperGasApiResponseItemEIP1559,
} from '../model'
import { TxnUpdate } from '../reducers/txn'
import {
  error0xToString,
  getNetworkConfig,
  getTokenAddress,
  isNativeTokenForNetwork,
  sleep,
} from '../utils'
import { getEthUserBalance } from './accountService'
import amplitudeService from './amplitudeService'
import { getErc20Contract, getSpenderAddress, getTokenAllowance } from './chainService'
import gtmService from './gtmService'
import { store } from './reduxService'
import { getTokensPrices } from './tokenService'

const JSONHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export interface ServiceParams {
  updateTxn: (txnUpdate: TxnUpdate) => void
  startTxn: (txnUpdate: TxnUpdate) => void
  resetTxn: (txnUpdate: TxnUpdate) => void
  errorTxn: (txnUpdate: TxnUpdate) => void
  cancelTxn: (txnUpdate: TxnUpdate) => void
  type: TradeType
  tokenTo: TokenV3
  tokenNetwork: string
  tokenFrom: TokenV3
  txn: Transaction
  update: (value: Partial<TokenWithApprovalContext>) => void
  account: Address | undefined | null
  library: Web3
  chainId?: number
  walletNetwork?: string
  slippage: number
  currentToken?: TokenWithApprovalContext
  quoteToken?: TokenWithApprovalContext
  blockNumber?: number
  spender: TokenSpenders
}

export const calculateGasCosts = async ({
  estimatedGasAmount,
  selectedGasPrice,
  account,
  fromAmount,
  tokenFrom,
  currentToken,
  library,
  type,
  updateTxn,
}: {
  account: Address | null | undefined
  currentToken: TokenV3
  estimatedGasAmount: BigNumber
  fromAmount: BigNumber
  library: Web3
  selectedGasPrice: BigNumber
  tokenFrom: TokenV3
  type: TradeType
  updateTxn?: (txnUpdate: TxnUpdate) => void
}): Promise<{ gasCostInNativeToken: BigNumber; gasCosts: BigNumber; isEnoughForGas: boolean }> => {
  try {
    const gasCostInNativeToken = estimatedGasAmount.times(selectedGasPrice).div(10 ** 18)

    if (!account) {
      throw new Error('account required')
    }

    const balance = await getEthUserBalance(account, library, tokenFrom.network, 'pending') // returns balance in native token

    const nativeTokenBalance = balance.div(10 ** 18)
    const fromAmountToken = isNativeTokenForNetwork(tokenFrom) && fromAmount ? fromAmount : 0
    const isEnoughForGas = nativeTokenBalance.minus(fromAmountToken).gt(gasCostInNativeToken)

    const networkConfig = getNetworkConfig(currentToken.network)

    // with currentToken and quoteToken prcies we allways do price polling for native token. So it should be in TokensState
    const storeTokensWithPrices = store.getState().tokens.tokens
    let nativeTokenPriceUSD = storeTokensWithPrices.find(
      (x) => x.id === networkConfig.native_token.id
    )?.priceUSD

    if (!nativeTokenPriceUSD) {
      // if we didn't fetch price on currentTokenChange - trying to fetch native token price separately
      nativeTokenPriceUSD = (await getTokensPrices([networkConfig.native_token.id]))[0]
        ?.token_price_usd

      if (nativeTokenPriceUSD === undefined) {
        throw new Error(
          `Could not calculate gas costs. The ${networkConfig.name} network does not have a native token`
        )
      }
    }
    const gasCosts = gasCostInNativeToken.times(nativeTokenPriceUSD)

    return { gasCostInNativeToken, gasCosts, isEnoughForGas }
  } catch (error: ReturnType<Error>) {
    const errorMessage = error.message?.includes('Internal JSON-RPC error')
      ? tokenFrom?.network === 'polygon'
        ? RPC_ERROR_MESSAGE_POLYGON
        : RPC_ERROR_MESSAGE
      : DEFAULT_ERROR_MESSAGE
    updateTxn &&
      updateTxn({
        key: type,
        patch: {
          lastRequest: `web3.eth.getBalance(${account})`,
          isLoading: false,
          approvalInProgress: false,
          txnError: {
            kind: ErrorKind.rpc,
            message: errorMessage,
            originalError: `${error} calculate-gas-costs`,
          },
          error: errorMessage,
          type,
          fromTokenAddress: tokenFrom?.address,
          tokenNetwork: tokenFrom?.network,
          account,
        },
      })

    throw error
  }
}

export const checkTokenAllowance = async ({
  account,
  tokenFrom,
  library,
  spender,
  update,
}: {
  account?: string | null
  tokenFrom: TokenV3
  library: Web3
  spender: TokenSpenders
  update?: (value: Partial<TokenWithApprovalContext>) => void
}): Promise<{
  isTokenApproved: boolean | null
  tokenApproveError: string | null
  tokenAllowance?: BigNumber
}> => {
  try {
    if (!tokenFrom || !tokenFrom?.id) {
      throw new Error('no-tokenFrom')
    }

    if (isNativeTokenForNetwork(tokenFrom) && spender === TokenSpenders.zeroX) {
      update &&
        update({
          [spender]: {
            isTokenApproved: true,
            tokenApproveError: null,
            tokenAllowance: new BigNumber(Infinity),
          },
        })
      return {
        isTokenApproved: true,
        tokenApproveError: null,
        tokenAllowance: new BigNumber(Infinity),
      }
    }

    if (!account) {
      throw new Error('account required')
    }

    const web3 = library
    const networkConfig = getNetworkConfig(tokenFrom.network)
    const tokenAddress = getTokenAddress(tokenFrom)
    const spenderAddress = getSpenderAddress(spender, networkConfig)
    if (!spenderAddress) {
      throw new Error('spender address is not defined during checking allowance')
    }
    const tokenAllowance = await getTokenAllowance(
      tokenAddress,
      spenderAddress,
      account,
      networkConfig.name,
      web3
    )

    const isTokenApproved = tokenAllowance.gt(0)

    update &&
      update({
        [spender]: {
          isTokenApproved,
          tokenApproveError: null,
          tokenAllowance,
        },
      })
    return { isTokenApproved, tokenApproveError: null, tokenAllowance }
  } catch (error) {
    console.error(error)
    const { message } = error as { message: string | undefined }
    if (message === 'no-tokenFrom') {
      return {
        isTokenApproved: false,
        tokenApproveError: message,
        tokenAllowance: undefined,
      }
    }
    if (message === RPC_ERR) {
      update &&
        update({
          [spender]: {
            isTokenApproved: false,
            tokenApproveError:
              tokenFrom.network === 'polygon' ? RPC_ERROR_MESSAGE_POLYGON : RPC_ERROR_MESSAGE,
            tokenAllowance: null,
          },
        })
      return {
        isTokenApproved: null,
        tokenApproveError:
          tokenFrom.network === 'polygon' ? RPC_ERROR_MESSAGE_POLYGON : RPC_ERROR_MESSAGE,
        tokenAllowance: undefined,
      }
    } else {
      update &&
        update({
          [spender]: {
            isTokenApproved: false,
            tokenApproveError: message,
            tokenAllowance: null,
          },
        })
      return {
        isTokenApproved: false,
        tokenApproveError: message || DEFAULT_ERROR_MESSAGE,
        tokenAllowance: undefined,
      }
    }
  }
}

export const approve = async ({
  account,
  library,
  sellAmount,
  type,
  tokenFrom,
  spender,
  update,
  updateTxn,
  cancelTxn,
  txn,
}: {
  account: Address | null | undefined
  library: Web3
  sellAmount: BigNumber
  type: TradeType
  tokenFrom: TokenV3
  spender: TokenSpenders
  update: (value: Partial<TokenWithApprovalContext>) => void
  updateTxn: (txnUpdate: TxnUpdate) => void
  cancelTxn: (txnUpdate: TxnUpdate) => void
  txn: Transaction
}): Promise<void> => {
  let lastRequest = ''
  if (!txn.gasPrice) {
    throw new Error('missing gas Price for approve txn')
  }
  const tokenAddress = getTokenAddress(tokenFrom)
  updateTxn({
    key: type,
    patch: {
      lastRequest,
      approvalInProgress: true,
      type,
      txnError: { message: '', originalError: '' },
    },
  })

  if (
    tokenAddress === ZERO_X_NATIVE_TOKEN_ADDRESS ||
    (isNativeTokenForNetwork(tokenFrom) && spender === TokenSpenders.zeroX)
  ) {
    update({ [spender]: { isTokenApproved: true, approvalComplete: true } })
    updateTxn({
      key: type,
      patch: {
        lastRequest,
        isLoading: false,
        approvalInProgress: false,
        hashTxn: undefined,
        type,
        txnError: { message: '', originalError: '' },
        fromTokenAddress: tokenFrom.address,
        tokenNetwork: tokenFrom.network,
        account,
      },
    })
    return
  }

  try {
    const networkConfig = getNetworkConfig(tokenFrom.network)
    const spenderAddress = getSpenderAddress(spender, networkConfig)
    if (!spenderAddress) {
      throw new Error('spender address is not defined during approval')
    }
    lastRequest = `erc20Contract.methods.allowance(${account}, ${spenderAddress})`

    const erc20allowance = await getTokenAllowance(
      tokenAddress,
      spenderAddress,
      account,
      networkConfig.name,
      library
    )

    if (new BigNumber(sellAmount).gt(erc20allowance)) {
      // if not enough allowance then we need to send approval txn
      // with infinite token amount (2^256 - 1)
      update({ [spender]: { isTokenApproved: false, approvalComplete: false } })
      updateTxn({
        key: type,
        patch: {
          lastRequest,
          isLoading: true,
          estimateLoading: null,
          approvalInProgress: true,
          hashTxn: undefined,
          type,
          txnError: { message: '', originalError: '' },
          fromTokenAddress: tokenFrom.address,
          tokenNetwork: tokenFrom.network,
          account,
        },
      })

      const erc20Contract = getErc20Contract(library, tokenAddress)
      const gas = await erc20Contract.methods
        .approve(spenderAddress, `0x${MAX_UINT.toString(16)}`)
        .estimateGas({ from: account })

      let txnData

      const gasFee: GasFeeType = store.getState().settings.settingsData.gasFee
      if (
        txn.gasPrice.fast instanceof BigNumber &&
        txn.gasPrice.instant instanceof BigNumber &&
        txn.gasPrice.overkill instanceof BigNumber
      ) {
        const gasData = (txn.gasPrice as GasPrice)[gasFee]
        const gasPrice = gasData.toFixed()
        txnData = { from: account, gas, gasPrice }
      } else {
        const gasData = (txn.gasPrice as GasPriceEIP1559)[gasFee]
        const maxPriorityFee = gasData.maxFee.toFixed()
        const maxPriorityFeePerGas = gasData.maxPriorityFee.toFixed()
        txnData = { from: account, gas, maxPriorityFee, maxPriorityFeePerGas }
      }

      lastRequest = `erc20Contract.methods.approve(${spenderAddress}, 0x${MAX_UINT.toString(16)})`

      await erc20Contract.methods
        .approve(spenderAddress, `0x${MAX_UINT.toString(16)}`)
        .send(txnData)
        .once('transactionHash', (hash: string) => {
          updateTxn({
            key: type,
            patch: {
              lastRequest,
              isLoading: true,
              approvalInProgress: true,
              hashTxn: hash,
              txnError: { message: '', originalError: '' },
              fromTokenAddress: tokenFrom.address,
              tokenNetwork: tokenFrom.network,
              account,
            },
          })
        })
        .once('confirmation', () => {
          update({ [spender]: { isTokenApproved: true, approvalComplete: true } })
          updateTxn({
            key: type,
            patch: {
              lastRequest,
              isLoading: false,
              approvalInProgress: false,
              hashTxn: '',
              txnError: { message: '', originalError: '' },
              fromTokenAddress: tokenFrom?.address,
              tokenNetwork: tokenFrom.network,
              account,
            },
          })
        })
        .catch((error: HttpError) => {
          console.error(error)
          if (error.code === REQUEST_DENIED_BY_USER_ERROR_CODE) {
            cancelTxn({
              key: type,
              patch: {
                lastRequest,
                isLoading: false,
                approvalInProgress: false,
                txnError: { message: '', originalError: '' },
                type,
                fromTokenAddress: tokenFrom?.address,
                tokenNetwork: tokenFrom?.network,
                account,
                receipt: undefined,
              },
            })
          } else {
            const errorMessage = error.message?.includes('Internal JSON-RPC error')
              ? tokenFrom?.network === 'polygon'
                ? RPC_ERROR_MESSAGE_POLYGON
                : RPC_ERROR_MESSAGE
              : DEFAULT_ERROR_MESSAGE
            updateTxn({
              key: type,
              patch: {
                lastRequest,
                isLoading: false,
                approvalInProgress: false,
                txnError: {
                  kind: ErrorKind.rpc,
                  message: errorMessage,
                  originalError: `${error} approve`,
                },
                type,
                fromTokenAddress: tokenFrom?.address,
                tokenNetwork: tokenFrom?.network,
                account,
              },
            })
          }
          update({ [spender]: { isTokenApproved: false, approvalComplete: true } })
        })
    }
  } catch (error: ReturnType<Error>) {
    console.error(error)
    updateTxn({
      key: type,
      patch: {
        lastRequest,
        isLoading: false,
        approvalInProgress: false,
        txnError: {
          kind: ErrorKind.dex,
          message: DEFAULT_ERROR_MESSAGE,
          originalError: error.message,
        },
        type,
        fromTokenAddress: tokenFrom.address,
        tokenNetwork: tokenFrom.network,
        account,
      },
    })
    update({ [spender]: { isTokenApproved: false, approvalComplete: true } })
  }
}

export const getGasPrice = async ({
  tokenNetwork,
  updateTxn,
  type,
}: {
  tokenNetwork: string
  updateTxn: (txnUpdate: TxnUpdate) => void
  type: TradeType
}): Promise<void> => {
  let lastRequest = ''

  try {
    const { networksConfig } = store.getState()
    const networkSettings = networksConfig?.data.find((network) => network.name === tokenNetwork)
    if (!networkSettings) {
      throw new Error(`Settings for network ${tokenNetwork} wasn't found`)
    }
    const gasAPIURL = networkSettings.zerox_api.wrapper_gas_url

    lastRequest = gasAPIURL

    const gasPriceResponse: ZeroXGasApiResponse | undefined = await externalFetch<{
      result: (ZeroXGasApiResponseItem | ZeroXWrapperGasApiResponseItemEIP1559)[]
    }>(gasAPIURL, {
      init: {
        headers: JSONHeaders,
      },
      onError: async (errorResponse) => {
        let data
        try {
          data = await errorResponse.clone().json()
        } catch (e) {
          const textResponse = await errorResponse.clone().text()
          throw new Error(
            `Unable to parse "${textResponse}" of ${lastRequest}. It is not a valid JSON.`
          )
        }

        const zeroXError = new Error(error0xToString(data, GAS_ERROR_MESSAGE))
        zeroXError.name = ErrorKind.zeroX
        throw zeroXError
      },
    }).catch((e) => {
      throw e
    })

    if (!gasPriceResponse) {
      throw new Error('Gas price response was empty.')
    }

    const gasPriceData =
      gasPriceResponse.result.find((e) => e.source === 'DEXGURU') ||
      gasPriceResponse.result.find((e) => e.source === 'MEDIAN')

    if (!gasPriceData) {
      throw new Error('Gas price response did not include price data.')
    }
    const gasPrice = calculateGasPrices(gasPriceData)

    updateTxn({
      key: type,
      patch: {
        lastRequest,
        gasPrice,
        txnError: { message: '', originalError: '' },
      },
    })
  } catch (error: ReturnType<Error>) {
    console.error('gas price error', error)
    let kind = ErrorKind.zeroX
    if (error.name !== ErrorKind.zeroX) {
      kind = ErrorKind.dex
    }
    updateTxn({
      key: type,
      patch: {
        // isLoading: false, ?
        lastRequest,
        type,
        txnError: {
          kind,
          message: GAS_ERROR_MESSAGE,
          originalError: error.message,
        },
      },
    })
  }
}

export const sendTradeTransaction = async ({
  currentToken,
  txn,
  quoteToken,
  fromAmountSelectedCurrency,
  type,
  library,
  updateTxn,
  cancelTxn,
  submitTxn,
  confirmTxn,
  errorTxn,
  txnParams,
  tip,
  account,
  tokenFrom,
  tokenTo,
  tokenNetwork,
  revenue,
}: {
  currentToken?: TokenV3
  txn: Transaction
  quoteToken?: TokenV3
  fromAmountSelectedCurrency: BigNumber
  type: TradeType
  library: Web3
  updateTxn: (txnUpdate: TxnUpdate) => void
  submitTxn: (txnUpdate: TxnUpdate) => void
  confirmTxn: (txnUpdate: TxnUpdate) => void
  errorTxn: (txnUpdate: TxnUpdate) => void
  cancelTxn: (txnUpdate: TxnUpdate) => void
  txnParams: TxnParams
  tip: number
  account?: string | null
  tokenFrom: TokenV3
  tokenTo: TokenV3
  tokenNetwork: string
  revenue: BigNumber
}): Promise<void> => {
  let lastRequest = ''
  let hashTxn: string | undefined = undefined
  const gasFee: GasFeeType = store.getState().settings.settingsData.gasFee

  try {
    if (!currentToken || !quoteToken) {
      throw new Error('currentToken and quoteToken cannot be empty')
    }

    if (!library || !account || !txnParams) {
      throw new Error('web3 connection, or wallet, or txn params are missed')
    }
    if (!txn.gasPrice) {
      throw new Error('gasPrice is missed')
    }

    // Don't send analytics about shitcoins with extra high trade volumes
    if (fromAmountSelectedCurrency.lt(new BigNumber(GTM_PURCHASE_TRESHOLD_TOP))) {
      gtmService.v3.beginCheckout(currentToken, quoteToken)
      gtmService.v4.beginCheckout(currentToken, quoteToken)
    }
    updateTxn({
      key: type,
      patch: {
        lastRequest,
        fromTokenAddress: tokenFrom.address,
        toTokenAddress: tokenTo.address,
        tokenNetwork,
        isLoading: true,
        type,
        balanceHasChanged: false,
        txnVerificationInProgress: true,
        txnError: { message: '', originalError: '' },
        hashTxn,
        account,
        tip,
      },
    })

    // when gasPrice: GasPrice
    if (
      txn.gasPrice.fast instanceof BigNumber &&
      txn.gasPrice.instant instanceof BigNumber &&
      txn.gasPrice.overkill instanceof BigNumber
    ) {
      const gasData = (txn.gasPrice as GasPrice)[gasFee]
      txnParams.gasPrice = gasData.toFixed()
    } else {
      const gasData = (txn.gasPrice as GasPriceEIP1559)[gasFee]
      txnParams.maxPriorityFee = gasData.maxFee.toFixed()
      txnParams.maxPriorityFeePerGas = gasData.maxPriorityFee.toFixed()
      delete txnParams.gasPrice
    }

    lastRequest = `library.eth.sendTransaction(${JSON.stringify(txnParams)})`
  } catch (error: ReturnType<Error>) {
    amplitudeService.sendEvent(AmplitudeEvent.SWAP_TRANSACTION_FAILED, {
      revenue: revenue.toFixed(),
      token_id: tokenFrom.id,
      network: tokenNetwork,
      token_pair: tokenTo.id,
      slippage: txn.slippage,
      tip,
      gas_price: JSON.stringify(txn.gasPrice),
      networkFee: txn.gasCosts?.toFixed(),
      transactionId: txn.hashTxn,
      transactionType: txn.type,
    })

    // Extra catch for Polygon network where
    // Error is thrown in the middle of nowhere
    console.error('verify error', error)
    errorTxn({
      key: type,
      patch: {
        lastRequest,
        isLoading: false,
        balanceHasChanged: true,
        type: type,
        txnError: {
          kind: ErrorKind.dex,
          message: DEFAULT_ERROR_MESSAGE,
          originalError: error.message,
        },
      },
    })

    return
  }
  // Catch above is pre-confirmation

  try {
    library.eth
      .sendTransaction({ ...txnParams })
      .once('transactionHash', (hash: string) => {
        hashTxn = hash

        submitTxn({
          key: type,
          patch: {
            lastRequest,
            fromTokenAddress: tokenFrom.address,
            toTokenAddress: tokenTo.address,
            tokenNetwork,
            isLoading: true,
            hashTxn,
            balanceHasChanged: true,
            txnError: { message: '', originalError: '' },
            type,
            account,
            tip,
            gasFeeType: gasFee,
          },
        })
        if (fromAmountSelectedCurrency.lt(new BigNumber(GTM_PURCHASE_TRESHOLD_TOP))) {
          gtmService.v3.purchase(hashTxn, tip, fromAmountSelectedCurrency, currentToken, quoteToken)
          gtmService.v4.purchase(hashTxn, tip, fromAmountSelectedCurrency, currentToken, quoteToken)
        }
      })
      .once('confirmation', (confirmationNumber, receipt: TransactionReceipt) => {
        confirmTxn({
          key: type,
          patch: {
            lastRequest,
            isLoading: false,
            balanceHasChanged: true,
            type,
            hashTxn: receipt.transactionHash,
            txnError: { message: '', originalError: '' },
            account,
            receipt,
            tip,
            tokenNetwork,
            fromTokenAddress: tokenFrom.address,
            toTokenAddress: tokenTo.address,
          },
        })

        if (fromAmountSelectedCurrency.lt(new BigNumber(GTM_PURCHASE_TRESHOLD_TOP))) {
          amplitudeService.txnCompleted(fromAmountSelectedCurrency, currentToken, quoteToken, {
            ...txn,
            hashTxn: hashTxn,
          })
        }
      })
      .catch((error: HttpError) => {
        amplitudeService.sendEvent(AmplitudeEvent.SWAP_TRANSACTION_FAILED, {
          revenue: revenue.toFixed(),
          token_id: tokenFrom.id,
          network: tokenNetwork,
          token_pair: tokenTo.id,
          slippage: txn.slippage,
          tip,
          gas_price: JSON.stringify(txn.gasPrice),
          networkFee: txn.gasCosts?.toFixed(),
          transactionId: txn.hashTxn,
          transactionType: txn.type,
        })
        if (hashTxn) {
          // if hashTxn is still undefined that indicates that txn was not sent (i.e. rejected in Metamask)
          gtmService.v3.refund(hashTxn)
          gtmService.v4.refund(hashTxn)
        }

        if (error.code === REQUEST_DENIED_BY_USER_ERROR_CODE) {
          cancelTransactionByUser({ cancelTxn, type, account, lastRequest })
        } else {
          const errorMessage = error.message?.includes('Internal JSON-RPC error')
            ? tokenFrom?.network === 'polygon'
              ? RPC_ERROR_MESSAGE_POLYGON
              : RPC_ERROR_MESSAGE
            : DEFAULT_ERROR_MESSAGE

          errorTxn({
            key: type,
            patch: {
              lastRequest,
              isLoading: false,
              balanceHasChanged: true,
              type,
              hashTxn,
              txnError: {
                kind: ErrorKind.rpc,
                message: errorMessage,
                originalError: `${error} send-trade-transaction`,
              },
              account,
              // used for failure modal
              isPostSubmissionFailure: true,
              tokenNetwork,
              fromTokenAddress: tokenFrom.address,
              toTokenAddress: tokenTo.address,
            },
          })
        }

        console.error('Error during verification', error)
      })
  } catch (error: ReturnType<Error>) {
    amplitudeService.sendEvent(AmplitudeEvent.SWAP_TRANSACTION_FAILED, {
      revenue: revenue.toFixed(),
      token_id: tokenFrom.id,
      network: tokenNetwork,
      token_pair: tokenTo.id,
      slippage: txn.slippage,
      tip,
      gas_price: JSON.stringify(txn.gasPrice),
      networkFee: txn.gasCosts?.toFixed(),
      transactionId: txn.hashTxn,
      transactionType: txn.type,
    })

    // Extra catch for Polygon network where
    // Error is thrown in the middle of nowhere
    // Post submission version of the catch
    console.error('verify error after submit', error)
    errorTxn({
      key: type,
      patch: {
        lastRequest,
        isLoading: false,
        balanceHasChanged: true,
        type: type,
        hashTxn,
        txnError: {
          kind: ErrorKind.dex,
          message: DEFAULT_ERROR_MESSAGE,
          originalError: error.message,
        },
        // used for failure modal
        isPostSubmissionFailure: true,
      },
    })
  }
}

export const getSimpleSwapEstimate = async ({
  updateTxn,
  errorTxn,
  startTxn,
  type,
  tokenTo,
  sellAmount,
  tokenNetwork,
  tokenFrom,
  isNew,
  orderRate,
  spender,
}: {
  startTxn: (txnUpdate: TxnUpdate) => void
  errorTxn: (txnUpdate: TxnUpdate) => void
  updateTxn: (txnUpdate: TxnUpdate) => void
  type: TradeType
  tokenTo: TokenV3
  sellAmount: BigNumber
  tokenNetwork: string
  tokenFrom: TokenV3
  isNew?: boolean
  orderRate?: BigNumber
  spender?: TokenSpenders
}): Promise<TxnUpdate> => {
  const lastRequest = ''

  try {
    if (!tokenFrom || !tokenTo) {
      throw new Error("tokenFrom or tokenTo doesn't exist")
    }

    if (tokenFrom.id === tokenTo.id) {
      throw new Error('tokenFrom is equal to tokenTo')
    }

    if (!sellAmount.gt(0)) {
      throw new Error('Trying to sell negative amount')
    }

    // drop error
    updateTxn({
      key: type,
      patch: {
        lastRequest,
        estimateLoading: true,
        txnError: { message: '', originalError: '' },
      },
    })

    const quoteResponse = await getQuoteFrom0x(
      {
        tokenNetwork,
        sellAmount,
        updateTxn,
        type,
        tokenFrom,
        tokenTo,
      },
      false
    )

    if (!quoteResponse) {
      throw new Error("quoteResponse isn't found")
    }

    const sourcesString = quoteResponse.sources
      .filter((item: Source) => item.proportion > 0)
      .map((item: Source) => item.name)
      .join(', ')

    if (spender === TokenSpenders.oneInch && orderRate && orderRate.gt(0)) {
      const rate =
        type === TradeTypeEnum.sell
          ? orderRate.div(quoteResponse.price)
          : orderRate.div(new BigNumber(1).div(quoteResponse.price))
      quoteResponse.buyAmount =
        type === TradeTypeEnum.buy
          ? new BigNumber(quoteResponse.buyAmount).div(rate)
          : new BigNumber(quoteResponse.buyAmount).times(rate)
      // later this price will be used to sign limit order
      quoteResponse.price = orderRate.toFixed()
    }
    const estimatedAmount = new BigNumber(quoteResponse.buyAmount).dividedBy(10 ** tokenTo.decimals)
    const price = new BigNumber(quoteResponse.price)
    const payload = {
      key: type,
      patch: {
        lastRequest,
        amount: undefined,
        quoteResponse,
        estimatedAmount,
        price,
        sourcesString,
        estimateLoading: false,
        txnError: { message: '', originalError: '' },
      },
    }

    if (isNew) {
      startTxn(payload)
    } else {
      updateTxn(payload)
    }
    return payload
  } catch (error: ReturnType<Error>) {
    console.error('Error while getting a simple swap quote', error)
    const estimatedAmount = new BigNumber(0)
    const errorPayload = {
      key: type,
      patch: {
        lastRequest,
        quoteResponse: undefined,
        estimatedAmount,
        sourcesString: '',
        estimateLoading: false,
        txnError: {
          kind: ErrorKind.zeroX,
          message: ZERO_X_ERROR_MESSAGE,
          originalError: error.message,
        },
      },
    }
    errorTxn(errorPayload)
    return errorPayload
  }
}

export const getLimitOrderEstimate = async ({
  updateTxn,
  errorTxn,
  startTxn,
  type,
  tokenTo,
  sellAmount,
  tokenFrom,
  limitOrderRate,
  quoteResponse,
  isNew,
}: {
  startTxn: (txnUpdate: TxnUpdate) => void
  errorTxn: (txnUpdate: TxnUpdate) => void
  updateTxn: (txnUpdate: TxnUpdate) => void
  type: TradeType
  tokenTo: TokenV3
  sellAmount: BigNumber
  tokenFrom: TokenV3
  limitOrderRate: BigNumber
  quoteResponse: QuoteResponse
  isNew?: boolean
}): Promise<void> => {
  const lastRequest = ''

  try {
    if (!tokenFrom || !tokenTo) {
      return
    }

    if (tokenFrom.id === tokenTo.id) {
      return
    }

    if (!sellAmount.gt(0)) {
      return
    }

    // drop error
    updateTxn({
      key: type,
      patch: {
        lastRequest,
        estimateLoading: true,
        txnError: { message: '', originalError: '' },
      },
    })
    const rate = type === TradeTypeEnum.sell ? limitOrderRate : new BigNumber(1).div(limitOrderRate)
    const buyAmount = new BigNumber(sellAmount)
      .times(rate)
      .times(10 ** (tokenTo.decimals - tokenFrom.decimals))

    const estimatedAmount = buyAmount.div(10 ** tokenTo.decimals)

    await sleep(1000) // give some time to show form to animation
    const payload = {
      key: type,
      patch: {
        lastRequest,
        amount: undefined,
        estimatedAmount,
        estimateLoading: false,
        txnError: { message: '', originalError: '' },
        quoteResponse: { ...quoteResponse, buyAmount, price: limitOrderRate.toFixed() },
        price: limitOrderRate,
      },
    }

    if (isNew) {
      startTxn(payload)
    } else {
      updateTxn(payload)
    }
  } catch (error: ReturnType<Error>) {
    console.error('Error while getting a limit order estimate', error)
    const estimatedAmount = new BigNumber(0)

    errorTxn({
      key: type,
      patch: {
        lastRequest,
        quoteResponse: undefined,
        estimatedAmount,
        estimateLoading: false,
        txnError: {
          kind: ErrorKind.dex,
          message: 'Limit order estimate errorF',
          originalError: error.message,
        },
      },
    })
  }
}

export const getSwapEstimateWithGas = async ({
  account,
  currentToken,
  tip,
  library,
  selectedGasPrice,
  sellAmount,
  slippage,
  tokenFrom,
  tokenNetwork,
  tokenTo,
  type,
  updateTxn,
  startTxn,
  errorTxn,
  isNew = true,
}: {
  currentToken?: TokenV3
  slippage: number
  tokenFrom?: TokenV3
  tokenTo?: TokenV3
  account: Address | null | undefined
  tokenNetwork: string
  selectedGasPrice: BigNumber
  tip: number
  sellAmount: BigNumber
  library: Web3
  type: TradeType
  updateTxn: (txnUpdate: TxnUpdate) => void
  errorTxn: (txnUpdate: TxnUpdate) => void
  startTxn: (txnUpdate: TxnUpdate) => void
  isNew: boolean
}): Promise<void> => {
  const lastRequest = ''

  try {
    if (!currentToken) {
      throw new Error('currentToken cannot be empty')
    }
    if (!tokenFrom || !tokenTo) {
      throw new Error('tokenFrom and tokenTo cannot be empty')
    }
    if (tokenFrom.id === tokenTo.id) {
      throw new Error('buy and sell tokens should be different')
    }
    if (selectedGasPrice === null) {
      throw new Error('gas price cannot be empty')
    }

    if (!sellAmount.gt(0)) {
      throw new Error('amount cannot be empty')
    }

    if (!account) {
      throw new Error('account cannot be undefined')
    }

    // drop error
    updateTxn({
      key: type,
      patch: {
        lastRequest,
        estimateLoading: true,
        txnError: { message: '', originalError: '' },
        toTokenAddress: tokenTo.address,
        fromTokenAddress: tokenFrom.address,
        tokenNetwork: tokenFrom.network,
      },
    })

    const buyTokenPercentageFee = tip / 100
    const queryFee: QueryFee =
      buyTokenPercentageFee > 0
        ? {
            feeRecipient: referrerAddress,
            buyTokenPercentageFee,
          }
        : {}

    // slippage should be not in %. Otherwise 0x api will throw MUST_BE_LESS_THAN_OR_EQUAL_TO_ONE error
    const slippageQuery = (slippage / 100).toFixed(4)

    const takerAddress = account
    const gasPrice = selectedGasPrice?.gt(0) ? selectedGasPrice.toFixed(0) : undefined // ommit if gasPrice is 0

    const quoteResponse = await getQuoteFrom0x(
      {
        tokenNetwork,
        queryFee,
        takerAddress,
        gasPrice,
        sellAmount,
        slippageQuery,
        updateTxn,
        type,
        tokenFrom,
        tokenTo,
      },
      true
    )

    if (!quoteResponse) {
      return
    }

    const estimatedGasAmount = new BigNumber(quoteResponse.gas)
    const fromAmount = new BigNumber(quoteResponse.sellAmount).div(10 ** tokenFrom.decimals)

    const { gasCosts, isEnoughForGas } = await calculateGasCosts({
      estimatedGasAmount,
      selectedGasPrice,
      account,
      tokenFrom,
      currentToken,
      library,
      fromAmount,
      type,
      updateTxn,
    })

    const toAmount = new BigNumber(quoteResponse.buyAmount).dividedBy(10 ** tokenTo.decimals)
    const price = new BigNumber(quoteResponse.price)

    const txnParams = {
      from: account,
      to: quoteResponse.to,
      data: quoteResponse?.data,
      gasPrice: quoteResponse.gasPrice,
      gas: quoteResponse.gas,
      value: quoteResponse.value,
    }

    const payload = {
      key: type,
      patch: {
        lastRequest,
        quoteResponse,
        txnParams,
        amount: fromAmount,
        estimatedAmount: toAmount,
        estimatedGasAmount,
        price,
        gasCosts,
        isEnoughForGas,
        slippage,
        tip: tip,
        estimateLoading: false,
        txnError: { message: '', originalError: '' },
        toTokenAddress: tokenTo.address,
        fromTokenAddress: tokenFrom.address,
        tokenNetwork,
        account,
      },
    }

    if (isNew) {
      startTxn(payload)
    } else {
      updateTxn(payload)
    }
  } catch (error: ReturnType<Error>) {
    console.error('Error while getting swap estimate with gas', error)
    const errorMessage = error.message?.includes('Internal JSON-RPC error')
      ? tokenFrom?.network === 'polygon'
        ? RPC_ERROR_MESSAGE_POLYGON
        : RPC_ERROR_MESSAGE
      : ZERO_X_ERROR_MESSAGE
    errorTxn({
      key: type,
      patch: {
        txnError: {
          kind: ErrorKind.rpc,
          message: errorMessage,
          originalError: `${error} swap-estimate-withgas`,
        },
        lastRequest,
        estimateLoading: false,
        toTokenAddress: tokenTo?.address,
        fromTokenAddress: tokenFrom?.address,
        tokenNetwork,
        account,
      },
    })
  }
}

const getQuoteFrom0x = async (
  {
    tokenNetwork,
    queryFee,
    gasPrice,
    takerAddress,
    sellAmount,
    slippageQuery,
    updateTxn,
    type,
    tokenTo,
    tokenFrom,
  }: {
    tokenNetwork: string
    queryFee?: QueryFee
    gasPrice?: string
    takerAddress?: string
    sellAmount: BigNumber
    slippageQuery?: string
    updateTxn: (txnUpdate: TxnUpdate) => void
    type: TradeType
    tokenTo: TokenV3
    tokenFrom: TokenV3
  },
  doQuote: boolean
): Promise<QuoteResponse | undefined> => {
  let queryString = {}
  let zeroXAPIUrl = ''
  let lastRequest = ''

  try {
    const { networksConfig } = store.getState()
    const networkSettings = networksConfig?.data?.find((network) => network.name === tokenNetwork)
    if (!networkSettings) {
      throw new Error(`Settings for network ${tokenNetwork} wasn't found`)
    }
    zeroXAPIUrl = networkSettings.zerox_api.wrapper_url

    const fromTokenId = isNativeTokenForNetwork(tokenFrom)
      ? ZERO_X_NATIVE_TOKEN_ADDRESS
      : getTokenAddress(tokenFrom)
    const toTokenId = isNativeTokenForNetwork(tokenTo)
      ? ZERO_X_NATIVE_TOKEN_ADDRESS
      : getTokenAddress(tokenTo)

    if (feeOnTransferTokens.find((item) => item.address === fromTokenId)) {
      throw new Error('WalletExecuteDelegateCallFailedError')
    }
    queryString = {
      ...queryFee,
      affiliateAddress: referrerAddress,
      buyToken: toTokenId,
      gasPrice,
      sellAmount: sellAmount.toFixed(0),
      sellToken: fromTokenId,
      slippagePercentage: slippageQuery,
      takerAddress,
    }

    const url = doQuote
      ? `${zeroXAPIUrl}/swap/v1/quote?${qs.stringify(queryString)}`
      : `${zeroXAPIUrl}/swap/v1/price?${qs.stringify(queryString)}`
    lastRequest = url

    const quoteResponse = await externalFetch<QuoteResponse>(url, {
      init: {
        headers: JSONHeaders,
      },
      onError: async (errorResponse) => {
        let data

        try {
          data = await errorResponse.clone().json()
        } catch (e) {
          const textResponse = await errorResponse.clone().text()
          throw new Error(
            `Unable to parse "${textResponse}" of ${lastRequest}. It is not a valid JSON.`
          )
        }

        const zeroXError = new Error(error0xToString(data, QUOTE_RESPONSE_ERROR_MESSAGE))
        zeroXError.name = ErrorKind.zeroX
        throw zeroXError
      },
    }).catch((e) => {
      throw e
    })

    if (!quoteResponse) {
      const zeroXError = new Error(LOST_QUOTE_RESPONSE_ERROR_MESSAGE)
      zeroXError.name = ErrorKind.zeroX
      throw zeroXError
    }

    return quoteResponse
  } catch (error: ReturnType<Error>) {
    console.error('Error while getting swap estimate', error)

    let kind = ErrorKind.zeroX
    if (error.name !== ErrorKind.zeroX) {
      kind = ErrorKind.dex
    }

    let message = ZERO_X_ERROR_MESSAGE
    if (
      error.message.includes(ZERO_X_ERRORS.WalletExecuteDelegateCallFailedError) ||
      error.message.includes(ZERO_X_ERRORS.PancakeSwapFeature) ||
      error.message.includes(ZERO_X_ERRORS.UnderBought)
    ) {
      message = WALLET_EXECUTE_DELEGATE_ERROR_MESSAGE
    }

    updateTxn({
      key: type,
      patch: {
        txnError: {
          kind,
          message: message,
          zeroXRequestUrl: error.url,
          originalError: error.message,
        },
        lastRequest,
        estimateLoading: false,
        toTokenAddress: tokenTo.address,
        fromTokenAddress: tokenFrom.address,
        tokenNetwork,
        account: takerAddress,
      },
    })
  }

  return
}

export const clearTransaction = ({
  resetTxn,
  type,
}: {
  resetTxn: (txnUpdate: TxnUpdate) => void
  type: TradeType
}): void => {
  const lastRequest = ''

  resetTxn({
    key: type,
    patch: {
      lastRequest,
      estimateLoading: null,
      isLoading: false,
      hashTxn: undefined,
      balanceHasChanged: false,
      type: type,
      sourcesString: '',
      txnParams: {},
      txnError: { message: '', originalError: '' },
      amount: undefined,
      estimatedAmount: undefined,
      approvalInProgress: null,
      isEnoughForGas: undefined,
      fromTokenAddress: undefined,
      toTokenAddress: undefined,
      tokenNetwork: undefined,
      receipt: undefined,
    },
  })
}

export const cancelTransactionByUser = ({
  cancelTxn,
  type,
  account,
  lastRequest,
}: {
  cancelTxn: (txnUpdate: TxnUpdate) => void
  type: TradeType
  account: Address | null | undefined
  lastRequest: string
}): void => {
  cancelTxn({
    key: type,
    patch: {
      lastRequest,
      isLoading: false,
      balanceHasChanged: true,
      type,
      txnError: { message: `${REQUEST_DENIED_BY_USER_ERROR_CODE}` },
      account,
    },
  })
}

export const clearTransactionError = ({
  updateTxn,
  type,
}: {
  updateTxn: (txnUpdate: TxnUpdate) => void
  type: TradeType
}): void => {
  const lastRequest = ''

  updateTxn({
    key: type,
    patch: {
      lastRequest,
      type: type,
      error: '',
      txnError: { message: '', originalError: '' },
      isEnoughForGas: true,
      toTokenAddress: undefined,
      fromTokenAddress: undefined,
      tokenNetwork: undefined,
    },
  })
}

export const clearToken = ({
  update,
}: {
  update: (value: Partial<TokenWithApprovalContext>) => void
}): void => {
  update({
    zeroX: {
      isTokenApproved: undefined,
      tokenApproveError: '',
      approvalComplete: null,
    },
    oneInch: {
      isTokenApproved: undefined,
      tokenApproveError: '',
      approvalComplete: null,
    },
  })
}

export const clearTokenError = async ({
  update,
}: {
  update: (value: Partial<TokenWithApprovalContext>) => void
}): Promise<void> => {
  update({
    zeroX: {
      tokenApproveError: '',
    },
    oneInch: {
      tokenApproveError: '',
    },
    error: undefined,
  })
}

export const validateTxnParams = (txnParams: TxnParams): boolean =>
  !!txnParams.from && !!txnParams.to && !!txnParams.data && !!txnParams.gasPrice && !!txnParams.gas
